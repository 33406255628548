const isEnabled = () => ((window.IntercomIncludeInDevelopment || window.environment === 'production') && typeof Intercom === 'function' && window.IntercomAppId);

exports.onInitialClientRender = () => {
  if (!isEnabled()) {
    return;
  }

  window.Intercom('boot', {
    app_id: window.IntercomAppId,
    hide_default_launcher: window.HideDefaultLauncher,
    custom_launcher_selector: window.CustomLauncherSelector,
  });
};

exports.onRouteUpdate = function ({ location }) {
  if (!isEnabled()) {
    return;
  }

  window.Intercom('update');
};
