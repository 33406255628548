import React from 'react';

export default function VideoPlayer({
    videoID,
    width = '100%',
    height = '100%',
    title = '',
    padding = '56.25% 0 0 0',
    position = 'absolute'
}) {
    return (
        <div className="relative flex-auto">
            <div
                style={{
                    padding,
                    position: 'relative',
                    width: '100%',
                }}
            >
                <iframe
                    src={`https://player.vimeo.com/video/${videoID}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                        position,
                        top: 0,
                        left: 0,
                        width,
                        height,
                    }}
                    title={title}
                ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
    );
}
