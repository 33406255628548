import { isBrowser } from './environment';

export const blogImageWrapperStyles = {
    margin: '50px auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const verticalVideoResponsiveness = () => {
    if (isBrowser()) {
        const mediaQuery = window.matchMedia('(max-width: 640px)');

        if (mediaQuery.matches)
            return {
                width: '100%',
                height: '600px',
                padding: 0,
                position: 'static',
            };
    }

    return { width: '100%', height: '100%' };
};
